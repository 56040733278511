@import url("https://fonts.cdnfonts.com/css/overused-grotesk");
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url("https://fonts.cdnfonts.com/css/inter");
html {
	scroll-behavior: smooth;
}
* {
	color: #101828;
}
div {
	white-space: pre-line;
}

*:lang(en) {
	font-family: "Inter", sans-serif;
}
*:lang(ko) {
	font-family: "Spoqa Han Sans Neo", sans-serif;
}

.fontHighlight {
	font-family: "Overused Grotesk", sans-serif;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ant-input:focus {
	box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	-webkit-box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.ant-input-affix-wrapper-focused {
	box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	-webkit-box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
